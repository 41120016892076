import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

import I18n from 'sholdi-providers/I18n'

import bs from 'sholdi-locales/public-bs.json'

const AuthLayout = ({ left, right, reverse, className }) => (
  <I18n locale="BS" lngDict={bs}>
    <div
      className={twMerge(
        'flex h-screen justify-end',
        reverse ? 'flex-row-reverse' : 'flex-row',
        className,
      )}
    >
      <div className="flex items-center justify-center overflow-y-auto px-2 lg:px-0 w-full lg:w-1/2 h-full">
        {left}
      </div>

      <div className="hidden lg:block h-screen overflow-y-hidden w-1/2 relative">
        {right}
      </div>
    </div>
  </I18n>
)

AuthLayout.displayName = 'AuthLayout'

AuthLayout.propTypes = {
  left: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  right: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  reverse: PropTypes.bool,
  className: PropTypes.string,
  leftClassName: PropTypes.string,
}

export default AuthLayout
